@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-gradient {
    background: rgb(99,102,241);
    background: linear-gradient(13deg, rgba(99,102,241,1) 0%, rgba(255,255,255,1) 75%);
}

.project-wrapper {
    min-height: 230px;
}

.project-overlay {
    height: 100%;
}

.cert-icon {
    max-width: 180px;
}

.hero-img {
    background-color: #f3f3f3;
}

.image-responsive {
    width: 100%;
    margin: auto;
}

.testimonial-wrapper {
    cursor: pointer;
}

.testimonial-wrapper:hover .testimonial-readmore{
    text-decoration: underline;
}

.arrow-link:hover .arrow {
    transform: translate(5px,0);
    -webkit-transform: translate(5px,0); /** Chrome & Safari **/
    -o-transform: translate(5px,0); /** Opera **/
    -moz-transform: translate(5px,0);
}

.arrow {
    transition: all .33s ease-in-out;
}

a.underline-grow{
    position: relative;
}

.underline-grow:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: rgb(249, 115, 22);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.underline-grow:hover:before {
    visibility: visible;
    transform: scaleX(1);
}

.btn-purple:hover {
    background-color: rgb(99, 102, 241);
}

.btn-purple {
    transition: all .33s ease-in-out;
}

@media (min-width: 1024px) {
    .custom-50-wrapper {
        width: 49%;
    }
}